const MENU = [
  {
    name: 'Item 1',
    link: '#item-1',
  },
  {
    name: 'Item 2',
    link: '#item-2',
  },
]

export default MENU
